<template>
  <v-card elevation="4" max-width="996" width="100%" rounded="lg" class="risk-block" color="white">
    <div class="px-4">
      <v-img class="mb-2 mx-sm-auto" heigth="40" max-width="184" src="@/assets/img/logo-long.svg"></v-img>
      <div class="d-flex my-7 justify-sm-center">
        <div v-for="i in 6" :key="i" class="step" :class="2 >= i ? 'primary' : ''"></div>
      </div>
      <v-btn
        @click="$router.push(`${$route.query.page || '/'}`)"
        depressed
        class="skip rounded"
        color="gray lighten-1"
        width="120"
        height="44"
      >
        <span class="gray--text">{{ $t('btn.skip') }}</span>
      </v-btn>
    </div>
    <div class="f30 font-weight-bold mb-2">{{ $t('risk.title') }}</div>
    <div class="gray--text mb-5">{{ $t('risk.desc') }}</div>
    <v-slide-group v-model="risk" class="px-4 justify-center" show-arrows="false" style="display: grid">
      <v-slide-item v-for="(item, i) in $t('risk.riskList')" :key="item.id" v-slot="{ active, toggle }">
        <v-card
          width="100%"
          max-width="256"
          height="300"
          outlined
          @click="toggle"
          :class="[active ? 'item' : '', i == 1 ? 'mx-md-5 mx-4' : '']"
          class="pa-5 d-flex flex-column justify-space-between align-center"
        >
          <img :src="require(`@/assets/img/risk${i + 1}.svg`)" alt="graf" />
          <div class="text-center">
            <div class="f18 font-weight-bold text-capitalize">{{ item.title }}</div>
            <div class="text-body-2 mt-2">{{ item.desc }}</div>
          </div>
          <v-card
            tile
            flat
            width="32"
            height="32"
            :color="active ? 'primary' : '#ebeff4'"
            class="rounded-circle mb-1 mt-2 d-flex align-center justify-center"
          >
            <v-card tile flat width="10" height="10" class="rounded-circle white"></v-card>
          </v-card>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <!-- <div class="d-flex justify-center mx-auto px-4">
      <v-card
        width="100%"
        max-width="256"
        height="300"
        outlined
        v-for="(item, i) in $t('risk.riskList')"
        :key="item.id"
        @click="risk == item.id ? (risk = '') : (risk = item.id)"
        :class="[risk == item.id ? 'item' : '', i == 1 ? 'mx-md-5 mx-4' : '']"
        class="pa-5 d-flex flex-column justify-space-between align-center"
      >
        <img :src="require(`@/assets/img/risk${i + 1}.svg`)" alt="graf" />
        <div class="text-center">
          <div class="f18 font-weight-bold text-capitalize">{{ item.title }}</div>
          <div class="text-body-2 mt-2">{{ item.desc }}</div>
        </div>
        <v-card
          tile
          flat
          width="32"
          height="32"
          :color="risk == item.id ? 'primary' : '#ebeff4'"
          class="rounded-circle mb-1 mt-2 d-flex align-center justify-center"
        >
          <v-card tile flat width="10" height="10" class="rounded-circle white"></v-card>
        </v-card>
      </v-card>
    </div> -->
    <v-spacer></v-spacer>
    <v-divider class="mt-5 mt-md-16 mb-6" v-if="!$vuetify.breakpoint.xs"></v-divider>
    <div class="d-flex flex-wrap-reverse justify-center px-4 mt-sm-0 mt-10">
      <v-btn :to="`/question/1${$route.query.page ? `?page=${$route.query.page}` : ''}`" depressed height="64" width="156" color="white">
        {{ $t('btn.back') }}
      </v-btn>
      <v-btn :disabled="!risk" depressed @click="action" max-width="226" width="100%" height="64" class="primary">
        {{ $t('btn.next') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      risk: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getProfile').catch((e) => {
      this.error = e.response.data.error;
      if (this.error == 'request__unauthorized') {
        this.$router.push('/login');
      }
    });
  },
  methods: {
    async action() {
      this.error = [];
      const formData = new FormData();
      formData.append('risk_profile', this.risk);
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          // this.$router.push('/document');
          setTimeout(() => {
            // if (this.profile.is_profile_completed) {
            //   this.$router.push('/');
            // } else if (!this.profile.preferred_market_sectors.length) {
            //   this.$router.push('/question/2');
            // } else if (!this.profile.income_sources.length) {
            //   this.$router.push('/question/3');
            // } else if (!this.profile.general_monthly_income) {
            //   this.$router.push('/question/4');
            // } else if (this.profile.invest_other_assets == null) {
            //   this.$router.push('/question/5');
            // } else {
            //   this.$router.push('/question/done');
            // }
            this.$router.push(`/question/2${this.$route.query.page ? `?page=${this.$route.query.page}` : ''}`);
          }, 500);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style lang="scss">
.risk-block {
  padding: 30px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  .item {
    border-color: var(--v-primary-base);
    background: #d2ebf2;
  }
  .skip {
    position: absolute;
    top: 32px;
    right: 32px;
  }
  .step {
    margin: 0 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ebeff4;
  }
  @media screen and (max-width: 959px) {
    padding: 36px 0;
  }
  @media screen and (max-width: 599px) {
    padding-bottom: 16px;
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 100vh;
    .skip {
      right: 16px;
    }
  }
}
</style>
